import {defineStore} from 'pinia';
import {pageData} from "@/page.config";

let originalSetInterval = window.setInterval;
let intervalTimerList = [];

let pageName = {}
function deCamelize(str, separator){separator = typeof separator === 'undefined' ? '-' : separator;return str.replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2').replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2').toLowerCase();}

function setPageName(page, title, dir){
    if (page){
        let page2 = deCamelize(page)
        if (!pageName[dir + '_' + page2] ){
            pageName[dir + '_' + page2] = title
        }
    }
}

Object.keys(pageData).forEach(key=>{
    let d = pageData[key];
    d.forEach(i=>{
        if (!i.dir) i.dir = key
        setPageName(i.page, i.title, i.dir)
        if (i.items && i.items.length){
            i.items.forEach(j=>{
                if (!j.dir) j.dir = key
                setPageName(j.page, j.title, j.dir)
            })
        }
    });
});

export const viewStore = defineStore('viewData', {
    state: () => {
        return {
            userInfo: {},
            banner: [],
            method: 'edit',
            mobileApp: false,
            userIsReady: true,
            dataIsReady: true,
            searchKeyword: '',
            menuIndex: 0,
            currentUser: {},
            appName: '',
            pageAreaName: '',
            pageAreaTitle: '',
            pageParameter: {},
            pageReloadFunction: ()=>{},
            pageSearchFunction: ()=>{},

            isLogin: false,

            toolbarName: '',
            mainMenuName: '',
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            drawerReloadFunction: ()=>{},
        }
    },
    getters: {
        pageTitle() {
            return this.pageAreaTitle
        }
    },
    actions: {
        openDrawer(drawerName, drawerTitle, drawerParameter){
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(()=>{
                console.log(drawerName)
                this.drawerName = drawerName
                setTimeout(this.drawerReloadFunction, 10)
            }, 280)
        },
        setPageInterval(callback, ms, keep){
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        clearPageInterval (){
            intervalTimerList.forEach(i=>{clearInterval(i);});
            intervalTimerList = [];
        },
        async changeView(moduleName, pageParameter, stopPush){
            pageParameter = pageParameter || {}
            this.pageParameter = pageParameter

            stopPush = stopPush || false;
            let s = moduleName.split('/')
            if (s.length === 1 && moduleName.indexOf('_') < 0) moduleName = 'components_' + moduleName
            this.pageReloadFunction = () =>{}
            this.pageSearchFunction = () =>{}

            this.pageAreaName = moduleName.replace('/', '_')
            // setTimeout(this.pageReloadFunction, 10)
            let tp = document.getElementById('toolbar_for_page');
            if (tp){
                tp.innerHTML = '';
                setTimeout(this.moveToolbarItems, 50)
                setTimeout(this.moveToolbarItems, 250)
                setTimeout(this.moveToolbarItems, 500)
                setTimeout(this.moveToolbarItems, 1000)
                setTimeout(this.moveToolbarItems, 2000)
            }
            this.pageAreaTitle = this.getPageName(this.pageAreaName)
            if (!stopPush) this.pushState()
            this.clearPageInterval()
        },
        moveToolbarItems(){
            let t = document.getElementById('move_to_toolbar');
            let tp = document.getElementById('toolbar_for_page');
            if (t && t.id === "move_to_toolbar"){
                tp.innerHTML = '';
                if (t && tp){
                    setTimeout(function () {
                        tp.innerHTML = '';
                        tp.appendChild(t);
                        t.id = "id_r" + Math.random();
                    }, 50);
                }
            }
        },
        pushState(){
            let s1 = JSON.stringify(this.pageParameter)
            let pushUrl = `view=${this.pageAreaName}`;
            let pageTitle = this.pageAreaTitle
            if (s1 !== "{}") pushUrl = pushUrl + `&parameter=${s1}`
            if (pageTitle){
                document.title = this.appName + " - " + pageTitle;
            }else{
                document.title = this.appName;
            }

            history.pushState({
                'view': this.pageAreaName,
                'parameter': s1,
            }, this.appName + pageTitle, location.pathname + '?' + pushUrl);
        },
        getPageName(name){
            return pageName[name] || "--"
        },
    },
})