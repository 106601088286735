<template>
    <div v-show="showFBBox" class="FBBox">
        <ul>
            <li><a href="https://www.facebook.com/uno.clothing5168">粉絲專頁</a></li>
            <li><a href="#" @click="fb1">與台南uno對話</a></li>
            <li><a href="#" id="fb-customer-chat2">與台中uno對話</a></li>
        </ul>
    </div>
    <div class="float-icon">
        <div class="to-top" :class="{'visible': showToTop }" ><i class="fa-solid fa-angle-up"></i></div>
        <div class="float-text">聯絡我們<br /><i class="fa-solid fa-angle-down"></i></div>
        <div class="line-icon"><a href="https://line.me/R/ti/p/%40azi3879a"><img src="images/line_icon_02.png" alt="台南LINE" /></a></div>
        <div class="line-icon"><a href="https://line.me/R/ti/p/%40weq8212h"><img src="images/line_icon_01.png" alt="台中LINE" /></a></div>
    </div>
    <div id="subMenu" :class="{'showSubMenu': displaySubMenu}" style="width: 330px !important;">
        <div class="left-menu">
            <AccordionList>
                <AccordionItem :key="category.title" v-for="(category, index) in subMenuCategory">
                    <template #summary>
                        <span style="font-size: 16px;font-weight: bold;padding: 10px 15px;" :id="category.title">{{ category.title }}</span>
                    </template>
                    <template #icon>
                        <span class="sp-close">＋</span>
                        <span class="sp-open">ㄧ</span>
                    </template>
                    <div class="accordion-body" :id="'acb' + index">
                        <a :key="cat.id" v-for="cat in category.items" :href="subMenuPageName +'.html?category=' + cat.id" :title="cat.title">{{ cat.title }}</a>
                    </div>
                </AccordionItem>
            </AccordionList>
        </div>
        <div class="clear"></div>
    </div>
    <div v-show="displaySubMenu" style="background: #333;width: 100%;height: 100%;opacity: 0.3;position: fixed;z-index: 99;transition: all .3s ease-out;" class="subMenu-mask" @click="subMenuClose"></div>
    <div id="wrapper" :class="{'showSubMenu': displaySubMenu, 'smallLogo': !isBigLogoVisible, 'smallLogoA': scrollTop > 80, 'smallLogoB': scrollTop > 110}">
        <div id="header" :class="{'fixed-top': showFixedTop && !displaySubMenu}" data-aos="fade" data-aos-duration="1000">
            <div class="container-xl">
                <div  class="logo"><a href="index.html" title="UNO"><img src="images/logo.png" alt="UNO" class="w-100" /></a></div>
                <div class="top-icon">
                    <a title="搜尋" class="icon-search" @click.stop.prevent="showSearch = !showSearch"><i class="fa-solid fa-magnifying-glass"></i></a>
                    <a v-show="!isLogin" href="login.html" title="" class="icon-member"><i class="fa-solid fa-user-gear"></i></a>
                    <a v-show="isLogin" href="member.html" :title="user" class="icon-member"><i class="fa-solid fa-user-check"></i></a>
                    <a href="cart.html" title="" class="icon-shopcar"><i class="fa-solid fa-cart-plus"></i></a>
                </div>
                <br class="clear" />
            </div>
            <!-- search form -->
            <div class="search-box" v-show="showSearch">
                <div class="input-group">
                    <input type="text" v-model="searchKeyword" class="form-control" placeholder="請輸入關鍵字" aria-label="Recipient's username" aria-describedby="button-addon2">
                    <button class="btn btn-outline-secondary" @click.stop.prevent="search" type="button" id="button-addon2">搜尋</button>
                    <!-- <a class="close"><i class="fa-solid fa-xmark"></i></a> -->
                </div>
            </div>
            <!-- search form end -->
            <div class="line"></div>
            <transition name="fade">
                <div class="logoBig"><a href="index.html" title="UNO"><img src="images/logo.png" alt="UNO" /></a></div>
            </transition>
            <!-- menu -->
            <div class="container-xxl menu">
                <div class="mobile-menu-btn container-fluid">
                    <div class="row">
                        <div class="col-6 mobile-menu-text">選單/MENU</div>
                        <div class="col-6 mobile-menu-icon"><i class="fa-solid fa-bars"></i></div>
                    </div>
                </div>
                <div class="mobile-menu-hide">
                    <a href="about.html?v=20220901" title="品牌介紹/ABOUT US">品牌介紹/ABOUT US</a>
                    <a v-show="showCustomize" title="訂製專區/CUSTOMIZE" @click.stop.prevent="showSubMenu(customCategory, 'customize')">訂製專區/CUSTOMIZE</a>
                    <a v-show="showShopping" title="線上購物/SHOPPING"  @click.stop.prevent="showSubMenu(shopCategory, 'shopping')">線上購物/SHOPPING</a>
                    <a v-show="showOEM === true && isLogin === false" href="login.html" title="客製代工/OEM">客製代工/OEM</a>
                    <a v-show="showOEM === true && isLogin" href="oem.html"  title="客製代工/OEM">客製代工/OEM</a>
                    <a href="contact.html" title="聯絡我們/CONTACT US">聯絡我們/CONTACT US</a>
                </div>
            </div>
            <!-- menu end -->
        </div>

        <div class="container-xxl menu"><div class="mobile-menu-hide"><!-- 墊高度的 --></div></div>
        <div style="height: 210px; background-color: #fff;" :class="{'h64': isBigLogoVisible}"><!-- 墊高度的 --></div>

        <!-- banner -->
        <div id="banner" class="container-xl" v-if="view.pageName ==='page_index'">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item" :class="{'active': index === 0}" v-for="(item, index) in view.banner" :key="item">
                        <iframe v-if="item.indexOf('youtube.com') > 0" width="100%" height="315" :src="item + '?rel=0&autoplay=1&loop=1'"></iframe>
                        <img v-if="item.indexOf('youtube.com') < 0" :src="item" class="d-block w-100" alt="...">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <!--  <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <!--  <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <!-- banner end -->
        <div class="side" v-if="view.pageName !== 'page_index'">
            <div class="container-lg" data-aos="fade" data-aos-duration="1000">
                <a href="index.html" title="">首頁HOME</a> |
                <span>{{ pageTitle }}</span>
            </div>
        </div>

        <div id="main" class="container" :class="view.pageName">
            <component v-bind:is="view.pageName"
                       @on-events="onComponentEvent">
            </component>
        </div>
        <div class="own-brand-box" data-aos="fade" data-aos-duration="1000">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 own-title"><h2>OWN-BRAND</h2><span>自有品牌</span></div>
                    <div class="col-xl-8 own-logo"><img src="images/ownBrandLogo.png" alt="" class="w-100" /></div>
                </div>
            </div>
        </div>
        <div id="footer">
            <div class="footer-info container">
                <div class="row">
                    <div class="col-md-12 col-lg-3 col-xl-4 footer-logo-icon">
                        <div class="footer-logo"><img src="images/logo.png" class="w-100" alt="" /></div>
                        <div class="share-text">分享/SHARE</div>
                        <div class="footer-icon">
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.uno-clothing.com.tw%2F" title="" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                            <a href="https://www.twitter.com/share?url=https%3A%2F%2Fwww.uno-clothing.com.tw%2F" title="" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                            <a href="https://social-plugins.line.me/lineit/share?url=https%3A%2F%2Fwww.uno-clothing.com.tw%2F" title="" target="_blank"><i class="fa-brands fa-line"></i></a>
                            <a href="http://instagram.com/sharer.php?u=https%3A%2F%2Fwww.uno-clothing.com.tw%2F" title="" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2 col-xl-2 footer-menu">
                        <a href="about.html" title="品牌介紹">品牌介紹</a>
                        <a v-if="showCustomize" href="#" @click.stop.prevent="showSubMenu(customCategory, 'customize')" title="訂製專區">訂製專區</a>
                        <a v-if="showShopping" href="#" @click.stop.prevent="showSubMenu(shopCategory, 'shopping')" title="線上購物">線上購物</a>
                        <a v-if="showOEM && !isLogin" href="login.html" title="客製代工/OEM">客製代工</a>
                        <a v-if="showOEM && isLogin" href="oem.html"  title="客製代工/OEM">客製代工</a>
                    </div>
                    <div class="col-md-3 col-lg-2 col-xl-2 footer-menu">
                        <span>顧客服務</span>
                        <a href="oem_sop.html" title="客製流程">客製流程</a>
                        <a href="shopping_sop.html" title="購物流程">購物流程</a>
                        <a href="contact.html" title="聯絡客服">聯絡客服</a>
                    </div>
                    <div class="col-md-6 col-lg-5 col-xl-4 footer-info-text">
                        <span>聯絡我們</span>702 台南市南區永成路二段119巷38號<br />0976-257-676<br />星期一~星期五08:30-17:30<br /><a href="https://www.facebook.com/uno.clothing5168" target="_blank" class="fb-link">FB粉絲專頁</a><br />台中市旱溪東路一段537-2號<br />0971-701-292
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="footer-text container">
                <div class="row">
                    <div class="col-lg-9">COPYRIGHT © UNO All Rights Reserved. <a href="https://www.vmoon.com.tw/" title="" target="_blank" class="vanmoon">WEBDESIGN BY VANMOON</a></div>
                    <div class="col-lg-3 footer-other-link"><a href="copyright.html" title="">著作權聲明</a>&nbsp; &nbsp;|&nbsp; &nbsp;<a href="secrecy.html" title="">隱私權聲明</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {AccordionList, AccordionItem} from "vue3-rich-accordion";
// import googleOneTap from 'google-one-tap';
let iframely = window['iframely']

let pc = {}
pc['AccordionList'] = AccordionList
pc['AccordionItem'] = AccordionItem
export default {
    name: 'App',
    setup() {
        try {
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: '174920059044-k90qbiplgt1vqej135mmcp2eujaodd7s.apps.googleusercontent.com',
                });
            });
        }catch (e) {
            console.log(e)
        }
    },
    computed:{
    },
    data() {
        return {
            showFBBox: false,
            showSearch: false,
            isLogin: false,
            user: 0,
            urlPage: '',
            pathName: '',
            pageTitle: '',
            accordionIndex: 0,
            accordionRunIndex: '',
            displaySubMenu: false,
            subMenuPageName: '',
            subMenuCategory: [],
            shopCategory: [],
            oemCategory: [],
            customCategory: [],
            searchKeyword: '',
            showToTop: false,
            showFixedTop: false,
            isBigLogoVisible: true,
            scrollTop: 0,
            showShopping: false,
            showOEM: false,
            showCustomize: false,
        }
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        let u = new URL(location.href);
        this.pathName = 'page_' + u.pathname.replace('/', '').replace('.html', '')
        if (this.pathName === 'page_') this.pathName = 'page_index'
        this.view.pageName = this.pathName
        this.pageTitle = this.getPageName(this.pathName)

        this.api.get(`/api/setting/?where={"name":"menu_config"}`).then(res=>{
            let r = res.data.data[0]
            let data = JSON.parse(r.value)
            this.showShopping = data.show_shopping === "true"
            this.showOEM = data.show_oem === "true"
            this.showCustomize = data.show_customize === "true"
            console.log(this.showOEM, this.showCustomize, this.showShopping)
            this.loadCategoryData()
        })

        this.checkUser()
        window['aos_init']()

        try {
            document.querySelectorAll('oembed[url]').forEach(element => {
                iframely.load(element, element.attributes.url.value);
            });
        }catch (e) {
            console.log(e)
        }
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        //
        // window.google.accounts.id.initialize({
        //     cancel_on_tap_outside: false,
        //     client_id: '174920059044-k90qbiplgt1vqej135mmcp2eujaodd7s.apps.googleusercontent.com',
        //     callback: this.handleCredentialResponse
        // });
        // window.google.accounts.id.prompt(); // 这将显示 One Tap 窗口
    },
    methods: {
        fb1(){
            document.getElementById('fb-customer-chat').click()
        },
        handleResize(){
            const addImgElement = document.querySelector('.add-img')
            if (addImgElement) {
                const addBoxHeight = addImgElement.clientHeight * 0.52
                const addBoxElement = document.querySelector('.add-box')
                if (addBoxElement) addBoxElement.style.maxHeight = `${addBoxHeight}px`
            }
        },
        handleCredentialResponse(response) {
            let data = {
                idToken: response.credential
            }
            this.api.post('/api/user/google_auth', data).then(res => {
                console.log(res)
                if (res.data.status === "success") {
                    this.checkUser()
                }
            })
        },
        subMenuClose(){
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            this.displaySubMenu = false;
            this.isBigLogoVisible = scrollTop <= 1;
        },
        handleScroll(){
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            this.showToTop = scrollTop > 200;
            this.showFixedTop = scrollTop > 145;
            this.isBigLogoVisible = scrollTop <= 145;
            this.scrollTop = scrollTop
        },
        search(){
            if (this.searchKeyword.length > 0) location.href = `/search.html?keyword=${this.searchKeyword}`
        },
        toggleMenu(index){
            if (this.accordionIndex === index) {
                this.accordionIndex = -1
            }else{
                this.accordionRunIndex = index
                setTimeout(()=>{
                    this.accordionIndex = index
                }, 300)
            }
        },
        visualItem(index){
            return index === this.accordionIndex
        },
        showSubMenu(target, page_name){
            this.displaySubMenu = true
            this.subMenuPageName = page_name
            this.subMenuCategory = target.filter(x=>x.is_show)
        },
        loadCategoryData(){
            if (this.showCustomize){
                this.api.get('/api/customize_category/list.json?size=10000&where={"is_show":true}').then(res=>{
                    let data = res.data.data
                    data.sort(((a, b) => {return a.sort > b.sort ? -1 : 1}))
                    this.customCategory = data.filter(x=>x.parent_id === 0)
                    data.forEach(x=>{
                        x.items = data.filter(m=>m.parent_id === x.id)
                    })
                })
            }
            if (this.showOEM){
                this.api.get('/api/oem_category/list.json?size=10000&where={"is_show":true}').then(res=>{
                    let data = res.data.data
                    data.sort(((a, b) => {return a.sort > b.sort ? -1 : 1}))
                    this.oemCategory = data.filter(x=>x.parent_id === 0)
                    data.forEach(x=>{
                        x.items = data.filter(m=>m.parent_id === x.id)
                    })
                })
            }
            if (this.showShopping){
                this.api.get('/api/shopping_category/list.json?size=10000&where={"is_show":true}').then(res=>{
                    let data = res.data.data
                    data.sort(((a, b) => {return a.sort > b.sort ? -1 : 1}))
                    this.shopCategory = data.filter(x=>x.parent_id === 0)
                    data.forEach(x=>{
                        x.items = data.filter(m=>m.parent_id === x.id)
                    })
                })
            }
        },
        onComponentEvent(v){
            console.log(v)
            if (typeof this[v] === "function"){
                this[v]()
            }
        },
        logout(){
            this.api.get('/api/user/logout').then(res=>{
                console.log(res)
                this.checkUser()
            })
        },
        checkUser(){
            if (location.host === '127.0.0.1'){
                let data = {"id": 559, "user": "admin", "roles": "admin", "avatar": "", "user_name": "\u7ba1\u7406\u54e1", "telephone": "", "address": "", "email": "", "shopping_cart": "[{\"product_id\":2,\"product\":\"2\u8a18\u93042\",\"image\":\"https://storage.googleapis.com/vmoon-app-001.appspot.com/upload_files/img02.png?r=0.9230589016919792\",\"prices\":[{\"name\":\"1\",\"_index\":0,\"_rowKey\":91,\"price\":\"500\"},{\"name\":\"2\",\"_index\":1,\"_rowKey\":97,\"price\":\"490\"},{\"name\":\"5\",\"_index\":2,\"_rowKey\":103,\"price\":\"470\"},{\"name\":\"10\",\"_index\":3,\"_rowKey\":109,\"price\":\"450\"},{\"name\":\"50\",\"_index\":4,\"_rowKey\":115,\"price\":\"400\"}],\"quantity\":20,\"manuscript\":1,\"file\":\"\",\"notes\":\"\",\"template\":{\"name\":\"---\",\"_index\":0,\"_rowKey\":43},\"processing\":{\"name\":\"\u6311\u5716\",\"_index\":0,\"_rowKey\":123,\"price\":\"100\"},\"group\":\"oem\"}]", "login": "true"}
                this.user = data.user_name
                this.view.userInfo = data
                this.isLogin = true
                this.events.emit('afterLogin')
            }else{
                this.api.get('/api/user/current').then(res=>{
                    let data = res.data
                    console.log(data)
                    if (data.login !== "true"){
                        if (['/oem.html', '/member.html', '/order_list.html', '/cart.html'].indexOf(location.pathname)){
                            // location.href = '/login.html'
                            this.view.changeView('login', {},true);
                        }
                        this.view.userInfo = data
                        this.view.isLogin = false
                        this.isLogin = false
                        this.events.emit('afterCheckUser')
                    }else{
                        this.user = data.user_name
                        this.view.userInfo = data
                        this.view.isLogin = true
                        this.isLogin = true
                        this.events.emit('afterLogin')
                    }
                })
            }
        },
        getPageName(name){
            return name in this.pageName ? this.pageName[name] : name
        }
    },
    components: pc
}
</script>

<style lang="stylus">
.search-box
    display block
#subMenu
    &.showSubMenu
        left 330px;
.accordion-list
    .accordion-item
        background-color: transparent !important
        border: none !important
        height calc(var(--summary-height))
        > .accordion-item__content
        > .accordion-item__summary
            padding 0 12px !important
        span.sp-close
            display block
        span.sp-open
            display none
    .accordion-item--open
        height: calc(var(--summary-height) + var(--content-height) + 12px) !important
        > .accordion-item__summary
            background-color: transparent !important
            border: none !important
        span.sp-close
            display none
        span.sp-open
            display block
#header
    height: 196px;
.mobile-menu-hide
    -webkit-transition: all .3s ease-out;
.left-menu
    left: -430px;
.showSubMenu .left-menu
    position: fixed;
    left: 0;
.menu-height
    height: 255px !important
.smallLogoA
    .logoBig
        opacity: 0.5;
.smallLogoB
    .logoBig
        opacity: 0;
#wrapper
    .logo
        opacity: 0;
        margin-left: 100px;
        transition: all 0.9s;
        display inline-block

    .logoBig
        height: 153px;
        transition: all 0;
        margin-top: 0;
        img
            transition: all 0.3s;

    &.smallLogo
        .logo
            opacity: 1
            margin-left: 0
        #header
            height: 100px;
        .logoBig
            opacity: 0;
            height: 0;
.h64
    height: 64px !important

.FBBox
    position: fixed;
    z-index: 999;
    background: #f5f5f5;
    bottom: 50px;
    right: 90px;
    padding: 10px;
    color: #fff !important;
    border: solid 3px #393939;

    li
        list-style: none;
        border-bottom: solid 1px #393939;
        padding: 10px 0;
        &:last-child
            border-bottom: none;
    &::after
        content: "";
        position: absolute;
        top: 50%;
        right: -10px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #393939; /* Arrow color */
        transform: translateY(-50%);

@media (min-width: 992px)
    .h64
        height: 0 !important

@media (max-width: 992px)
    #wrapper
        .logo
            position: absolute;
            left: -100px;


        &.smallLogo
            .logo
                left: 10px;

</style>
