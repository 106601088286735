import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {defineAsyncComponent} from 'vue'
import axios from 'axios'
import moment from "moment"
import VueAxios from 'vue-axios'
import {pageData} from '@/page.config'
import {viewStore} from '@/plugins/view'

import App from '@/App.vue'
import '@/registerServiceWorker'
import {useAccordion} from "vue3-rich-accordion"
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import "vue3-rich-accordion/accordion-library-styles.css"
import VueSweetalert2 from "vue-sweetalert2"
import 'sweetalert2/dist/sweetalert2.min.css';
import dataProxy from "@/plugins/data-proxy"
import fbProxy from "@/plugins/fb";


import mitt from "mitt";

axios.defaults.withCredentials = true
const pinia = createPinia()
const app = createApp(App)

let pageName = {}
function loadComponent(page, title, dir){
    pageName[dir + '_' + page] = title
    dir = dir || 'page'
    app.component(dir + '_' + page, defineAsyncComponent(() =>
        import(`@/${dir}/${page}.vue`)
    ))
}

Object.keys(pageData).forEach(key=>{
    let d = pageData[key];
    d.forEach(i=>{
        if (!i.dir) i.dir = key
        loadComponent(i.page, i.title, i.dir)
        if (i.items && i.items.length){
            i.items.forEach(j=>{
                if (!j.dir) j.dir = key
                loadComponent(j.page, j.title, j.dir)
            })
        }
    });
});


app
    .use(VueSweetalert2)
    .use(ViewUIPlus)
    .use(VueAxios, axios)
    .use(dataProxy)
    .use(fbProxy)
    .use(pinia)
    .use(useAccordion)


let vs = viewStore()
// app.config.globalProperties.Chart = Chart
app.config.globalProperties.pageName = pageName
app.config.globalProperties.pageData = pageData
app.config.globalProperties.$moment = moment
app.config.globalProperties.events = mitt()
app.config.globalProperties.view = vs
app.config.globalProperties.viewStore = vs

app.mount('#app')

