<template>
    <div class="float-icon">
        <!-- to top -->
        <div class="to-top"><i class="fa-solid fa-angle-up"></i></div>
        <!-- to top end -->
        <!-- messenger
        <div class="messenger"><a href=""><i class="fa-brands fa-facebook-messenger"></i></a></div>
        messenger end -->
    </div>
    <div id="group-wrapper">
        <component v-bind:is="view.pageName"
                   @on-events="onComponentEvent">
        </component>
        <div id="footer">COPYRIGHT © UNO All Rights Reserved.</div>
    </div>
</template>

<script>

let pc = {}

export default {
    name: 'App',
    setup() {
    },
    computed:{
    },
    data() {
        return {
            'isLogin': false,
            'user': 0,
            'expiryDate': new Date(2022, 10, 31, 0 , 0 ,0),
            'remainderSecond': '',
            'remainderMinute': '',
            'remainderHour': '',
            'remainderDay': '',
            'selectTab': '',
            'product': {
                'title': '',
            },
            'productList': [],
        }
    },
    mounted() {
        let u = new URL(location.href);
        this.pathName = 'page_' + u.pathname.replace('/', '').replace('.html', '')
        if (this.pathName === 'page_') this.pathName = 'page_index'
        this.view.pageName = this.pathName
    },
    methods: {
        onComponentEvent(v){
            if (typeof this[v] === "function"){
                this[v]()
            }
        },
    },
    components: pc
}
</script>

<style lang="stylus">
</style>
