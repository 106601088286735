let pageData = {
    'nav': [
        {title: '檔案', 'hideInMobileApp': false, class: '', 'items':[
                {title:"管理帳號", dir: "manage", page: "admin_list"},
                // {title:"帳號管理", dir: "manage", page: "application_user_list"},
                // {title:"郵件寄送設定", dir: "manage", page: "product"},
                {title:"hr"},
                {title:"列印", action: "print()"},
                {title:"hr"},
                {title:"登出", action: "this.logout()"},
            ]
        },
        {title: '檢視', 'hideInMobileApp': false, class: '', 'items':[
                {title:"編輯模式", action: "this.view.method = 'edit'", shortCut: "E"},
                {title:"檢視模式", action: "this.view.method = 'view'", shortCut: "V"},
                {title:"排序模式", action: "this.view.method = 'sort'", shortCut: "S"},
                {title:"刪除模式", action: "this.view.method = 'delete'", shortCut: "D"},
            ]
        },
        {title: '內容', 'hideInMobileApp': false, class: '', 'items':[
                {title:"首頁內容管理", dir: "manage", page: "index_page_config"},
                {title:"選單管理", dir: "manage", page: "menu_config"},
                {title:"hr"},
                // {title:"智付通金流設定", dir: "manage", page: "spgateway_config"},
                {title:"紅陽金流設定", dir: "manage", page: "sunpay_config"},
                {title:"hr"},
                {title:"郵件寄送樣版", dir: "manage", page: "mail_template"},
                {title:"郵件寄送設定", dir: "manage", page: "mail_config"},
            ]
        },
        {title: '訂製', 'hideInMobileApp': false, class: '', 'items':[
                {title:"訂製分類目錄", dir: "customize", page: "category"},
                {title:"訂製產品", dir: "customize", page: "product"},
                {title:"hr"},
                {title:"訂製訂單", dir: 'customize', page: "order"},
            ]
        },
        {title: '購物', 'hideInMobileApp': false, class: '', 'items':[
                {title:"購物分類目錄", dir: "shopping", page: "category"},
                {title:"購物產品", dir: "shopping", page: "product"},
                {title:"hr"},
                {title:"購物訂單", dir: "shopping", page: "order_data"},
            ]
        },
        {title: '代工', 'hideInMobileApp': false, class: '', 'items':[
                {title:"代工分類目錄", dir: "oem", page: "category"},
                {title:"代工產品", dir: "oem", page: "product"},
                {title:"hr"},
                {title:"代工訂單", dir: "oem", page: "order_data"},
            ]
        },
        {title: '團購', 'hideInMobileApp': false, class: '', 'items':[
                {title:"團購產品", dir: "group", page: "product"},
                {title:"hr"},
                {title:"團購訂單", dir: "group", page: "order_data"},
            ]
        },
        {title: '管理', 'hideInMobileApp': false, class: '', 'items':[
                {title:"會員清單", dir: "manage", page: "member_list"},
                {title:"hr"},
                {title:"訂單狀態", dir: "manage", page: "order_status"},
                {title:"hr"},
                {title:"付款記錄", dir: "manage", page: "payment_record"},
                {title:"付款方式", dir: "manage", page: "payment_type"},
                {title:"付款狀態", dir: "manage", page: "payment_status"},
                {title:"hr"},
                {title:"運費設定", dir: "manage", page: "freight"},
                {title:"寄送方式", dir: "manage", page: "freight_type"},
                {title:"寄送狀態", dir: "manage", page: "freight_status"},
            ]
        },
    ],
    'user': [
        {title: '登入頁面', page: 'Login'},
    ],
    'drawer': [
        {title:"編輯訂製分類",    dir: "customize", page: "category_edit"},
        {title:"編輯訂製產品",    dir: "customize", page: "product_edit"},
        {title:"編輯訂製訂單內容", dir: "customize", page: "order_edit"},

        {title:"編輯購物分類", dir: "shopping", page: "category_edit"},
        {title:"編輯購物產品", dir: "shopping", page: "product_edit"},
        {title:"編輯訂單內容", dir: "shopping", page: "order_data_edit"},

        {title:"編輯代工分類", dir: "oem", page: "category_edit"},
        {title:"編輯代工產品", dir: "oem", page: "product_edit"},
        {title:"編輯訂單內容", dir: "oem", page: "order_data_edit"},

        {title:"編輯團購內容", dir: "group", page: "product_edit"},
        {title:"編輯團購訂單內容", dir: "group", page: "order_data_edit"},

        {title:"編輯寄送方式", dir: "manage", page: "freight_type_edit"},
        {title:"編輯寄送狀態", dir: "manage", page: "freight_status_edit"},
        {title:"編輯付款方式", dir: "manage", page: "payment_type_edit"},
        {title:"編輯付款方式", dir: "manage", page: "payment_status_edit"},
        {title:"編輯訂單狀態", dir: "manage", page: "order_status_edit"},
        {title:"編輯運費"   , dir: "manage", page: "freight_edit"},
        {title:"編輯使用者資料", dir: "manage", page: "application_user_edit"},
        {title:"編輯管理者資料", dir: "manage", page: "admin_user_edit"},
        {title:"編輯信件樣版", dir: "manage", page: "mail_template_edit"},


    ],
    'component':[
    ],
    'page':[
        {title: '', page: 'index'},
        {title: '品牌介紹ABOUT US', page: 'about'},
        {title: '會員登入LOGIN', page: 'login'},
        {title: '聯絡我們CONTACT US', page: 'contact'},
        {title: '著作權聲明COPYRIGHT', page: 'copyright'},
        {title: '隱私權聲明PRIVACY POLICY', page: 'secrecy'},
        {title: '客製流程OEM SOP', page: 'oem_sop'},
        {title: '客製代工OEM', page: "oem"},
        {title: '購物車SHOPPING CAR', page: 'cart'},
        {title: '購物車SHOPPING CAR', page: 'checkout'},
        {title: '購物車SHOPPING CAR', page: 'complete'},
        {title: '線上購物SHOPPING', page: 'search'},
        {title: '線上購物SHOPPING', page: 'shopping'},
        {title: '線上購物SHOPPING', page: 'shopping_detail'},
        {title: '購物流程SHOPPING SOP', page: 'shopping_sop'},
        {title: '訂製專區CUSTOMIZE', page: 'customize'},
        {title: '忘記密碼FORGOT PASSWORD', page: 'password'},
        {title: '密碼重置RESET PASSWORD', page: 'reset_password'},
        {title: '訂單查詢ORDER DETAILS', page: 'order_list'},
        {title: '訂單查詢ORDER DETAILS', page: 'order_view'},
        {title: '訂單查詢ORDER DETAILS', page: 'order_no_member'},
        {title: '訂單查詢ORDER DETAILS', page: 'pay_error'},
        {title: '會員資料MEMBER PROFILE', page: 'member'},

        {title: '團購頁', page: 'group_buying', entry: "src/group.js", template: "public/index.html"},
        {title: '團購頁', page: 'group_checkout', entry: "src/group.js", template: "public/index.html"},
        {title: '團購頁', page: 'group_complete', entry: "src/group.js", template: "public/index.html"},

        {title: '後台管理', page: 'admin', entry: "src/admin/backend.js", template: "public/admin/backend.html"},
    ],
};


module.exports = {pageData}
