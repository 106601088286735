export default {
    install: (app) => {
        fbProxy.initFacebook()
        app.config.globalProperties.fb = fbProxy
    }
}
import axios from 'axios'
/* global FB */
class fb_class{
    userData = {}

    initFacebook(){
        let called = false;
        window.fbAsyncInit = function () {
            if (called) return;
            called = true;
            FB.init({
                appId: "836022951062727",
                cookie: true,
                xfbml: true,
                version: "v15.0",
            });

            FB.getLoginStatus(function (response) {   // Called after the JS SDK has been initialized.
                fbProxy.statusChangeCallback(response);        // Returns the login status.
            });

            FB.AppEvents.logPageView();
        };
        if (!called && typeof FB !== "undefined"){
            window.fbAsyncInit();
        }else{
            setTimeout(this.initFacebook, 100)
        }
    }

    login(){
        FB.login(function (response) {
            if (response.status === 'connected') {
                fbProxy.getUserInfo(true)
            } else {
                // The person is not logged into your webpage or we are unable to tell.
            }
        }, {scope: 'public_profile,email'});
    }

    statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
        if (response.status === 'connected') {   // Logged into your webpage and Facebook.
            fbProxy.getUserInfo();
        }
    }

    getUserInfo(sendRequest) {
        FB.api('/me', function (response) {
            fbProxy.userData = response
            if (sendRequest){
                axios.get(`/api/user/fb_login_callback.json?fid=${fbProxy.userData.id}&name=${fbProxy.userData.name}&picture=456&email=${fbProxy.userData.id}@facebook.account`).then(response=>{
                    console.log(response)
                    console.log('Successful login for: ' + response.name);
                    location.href = '/'
                })
            }
        });
    }
}

const fbProxy = new fb_class()