export default {
    install: (app) => {
        app.config.globalProperties.api = apiProxy
    }
}

import axios from 'axios'

let prefixUrl = location.hostname === '127.0.0.1' ? 'http://127.0.0.1:5000' : ''
let prefixUrl2 = location.hostname === '127.0.0.1' ? 'https://www.uno-clothing.com.tw' : ''
// let prefixUrl2 = location.hostname === '127.0.0.1' ? '' : ''


class api_class {
    get(url) {
        let prefix = prefixUrl2
        if (url.indexOf('/api/user') >= 0) prefix = prefixUrl
        return axios.get(prefix + url)
    }

    post(url, data) {
        let prefix = prefixUrl2
        if (url.indexOf('/api/user') >= 0) prefix = prefixUrl
        return axios.post(prefix + url, data)
    }

    patch(url, data) {
        let prefix = prefixUrl2
        if (url.indexOf('/api/user') >= 0) prefix = prefixUrl
        return axios.patch(prefix + url, data)
    }

    delete(url) {
        let prefix = prefixUrl2
        if (url.indexOf('/api/user') >= 0) prefix = prefixUrl
        return axios.delete(prefix + url)
    }
}

const apiProxy = new api_class()
